import { useEffect, useState } from 'react'
import PatientMassage from '../assets/images/patient-massage.png'
import PatientStandingArmStretch from '../assets/images/patient-standing-arm-stretch.png'
import PatientGenericInjury from '../assets/images/patient-generic-injury.png'
import PatientGenericPain from '../assets/images/patient-generic-pain.png'
import PatientGenericPregnant from '../assets/images/patient-generic-pregnant.png'
import PatientGenericBaby from '../assets/images/patient-generic-baby.png'
import './pages.css'

const servicesData = [
  {
    title: 'INJURIES & ACCIDENTS',
    image: PatientGenericInjury,
    description: [
      'We aid injury recovery through osteopathy by promoting natural healing. Using gentle manipulation of muscles, joints, and tissues, we improve blood flow, reduce inflammation, and enhance range of motion.',
      'By addressing imbalances and restoring alignment, we alleviate pain and prevent future injuries. Our personalised treatment plans consider the whole body, addressing all contributing factors for a faster and more complete recovery.',
    ],
    effectiveFor: ['Sports Injuries', 'Sprains', 'Strains', 'Repetitive stress injury'],
  },
  {
    title: 'CHRONIC PAIN',
    image: PatientGenericPain,
    description: [
      'We recognise that chronic pain can become overwhelming, therefore we manage chronic pain holistically, non-invasively and alongside a multi-disciplinary team as required. As osteopaths we focus on the musculoskeletal system, while also incorporating elements of breathing, identifying obstacles to recovery and setting goals to achieve optimal pain management.',
      'We identify and address the root causes of pain, promoting long-term relief and improved mobility. Our osteopathic treatments support natural healing, helping patients achieve a better quality of life with less pain and discomfort.',
    ],
    effectiveFor: ['Arthritis', 'Fibromyalgia', 'Back pain', 'Migraines'],
  },
  {
    title: 'PRE- & POST PARTUM',
    image: PatientGenericPregnant,
    description: [
      'During pregnancy, we enhance alignment and body function, boosting circulation and reducing swelling to increase overall comfort. We are here to support your body throughout this constantly evolving process.',
      'Postpartum, we help optimise the recover from childbirth strains, restoring balance and mobility. We support natural healing, helping mothers regain strength and reduce pain. We also offer postural advice for breastfeeding, movement, and a smooth return to exercise or work, easing the transition into motherhood.',
    ],
    effectiveFor: ['Back pain', 'Pelvic discomfort', 'Easier delivery', 'Sciatica', 'Coccyx'],
  },
  {
    title: 'NEWBORN, INFANTS & KIDS',
    image: PatientGenericBaby,
    description: [
      'During pregnancy, birth, and their subsequent growth, babies experience various strains, torsions, and growth spurts. We are dedicated to restoring alignment and easing these tensions to enhance feeding, digestion, movement, and overall development.',
      'For children, we can assist with growing pains, injuries, postural development, balance and co-ordination.',
    ],
    effectiveFor: [
      'Colic',
      'Reflux',
      'Feeding difficulties',
      'Motor skill development',
      'Growing pains',
      'Postural problems',
    ],
  },
]

const useMobileDetect = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 991)

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 991)
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return isMobile
}

const Services = () => {
  const isMobile = useMobileDetect()

  return (
    <>
      <div className={`container ${!isMobile ? '' : 'px-4'}`}>
        <IntroSection />
      </div>
      <div className={`container ${!isMobile ? '' : 'px-4'}`}>
        <TechniquesSection isMobile={isMobile} />
      </div>
      <div className={`${isMobile ? '' : 'container'}`}>
        <ServicesCardsSection isMobile={isMobile} />
      </div>
      <div className={`container ${!isMobile ? '' : 'px-4'}`}>
        <AppointmentInfoSection isMobile={isMobile} />
      </div>
    </>
  )
}

const IntroSection = ({ isMobile }) => (
  <div className='mb-80 row flex-lg-row-reverse align-items-center g-5'>
    <div className='col-10 col-sm-8 col-lg-6 d-none d-lg-block'></div>
    <div className='col-lg-6'>
      <h1 className='display-5 fw-bold font-frl font-db mb-40'>What we help with</h1>
      <h2 className='display-5 fw-bold lh-1 mb-3 font-db'>WHAT IS AN OSTEOPATH</h2>
      <p className='mb-4'>
        Osteopathy is a form of manual medicine that uses hands-on techniques to address the structure and
        function of the body to promote healing.
      </p>
      <h2 className='display-5 fw-bold lh-1 mb-3 font-db'>WHAT WE OFFER</h2>
      <p className='mb-4'>
        We offer a comprehensive range of osteopathic treatments tailored to all ages and unique health needs.
        Our goal is to encourage functionality while reducing stiffness, pain, and discomfort. We also provide
        exercise and lifestyle advice to assist recovery and optimise results.
      </p>
      <p>
        Our aim is to deliver effective, friendly care for the whole family, addressing the root cause of
        symptoms and promoting overall well-being. If a condition falls outside our scope of practice, we may
        refer you for additional imaging, second opinions, or specialized care.
      </p>
    </div>
  </div>
)

const TechniquesSection = ({ isMobile }) => (
  <div className='mb-80'>
    <div className='row flex-lg align-items-center g-5'>
      <div className={`col-12 col-lg-6 ${!isMobile ? 'pr-special' : ''}`}>
        <img
          src={PatientMassage}
          className='d-block mx-lg-auto img-fluid'
          alt='Patient Massage'
          width='700'
          height='500'
        />
      </div>
      <div className={`col-12 col-lg-6 ${!isMobile ? 'pl-special' : ''}`}>
        <h2 className='display-5 fw-bold lh-1 mb-3 font-db'>TECHNIQUES WE USE</h2>
        <ul className='font-db'>
          {[
            'Soft tissue massage',
            'Stretching',
            'Gentle manipulation',
            'Joint articulation',
            'Muscle energy technique',
            'Cranial',
            'Visceral',
            'Fascial unwinding',
          ].map((technique, index) => (
            <li key={index}>{technique}</li>
          ))}
        </ul>
        <p className='font-db'>
          We also commonly give advice on exercise and lifestyle to assist recovery or optimize results.
        </p>
      </div>
    </div>
  </div>
)

const ServicesCardsSection = ({ isMobile }) => (
  <div className={`${isMobile ? 'theme-background-2 p-4' : ''}`}>
    <div className={`${isMobile ? '' : 'theme-background-2 p-32 br-8'}`}>
      <div className='row row-cols-1 row-cols-md-2 g-4'>
        {servicesData.map((service, index) => (
          <ServiceCard key={index} {...service} />
        ))}
      </div>
    </div>
  </div>
)

const ServiceCard = ({ title, image, description, effectiveFor }) => (
  <div className='col'>
    <div className='card h-100 service-card text-white'>
      <div className='p-3'>
        <img src={image} className='card-img-top' alt={title} />
      </div>
      <div className='card-body'>
        <h2>{title}</h2>
        {description.map((para, index) => (
          <p key={index} className='mb-3'>
            {para}
          </p>
        ))}
        <div className='mb-3'>
          <p>
            <strong>This is effective for:</strong>
          </p>
        </div>
        <div className='card-tags'>
          {effectiveFor.map((tag, index) => (
            <div key={index} className='tag'>
              {tag}
            </div>
          ))}
        </div>
      </div>
    </div>
  </div>
)

const AppointmentInfoSection = ({ isMobile }) => (
  <div className='row flex-lg align-items-center g-5 py-5'>
    <div className={`col-12 col-lg-6 ${!isMobile ? 'pr-special-56' : ''}`}>
      <img
        src={PatientStandingArmStretch}
        className='d-block mx-lg-auto img-fluid'
        alt='Patient Standing Arm Stretch'
        width='700'
        height='500'
      />
    </div>
    <div className={`col-12 col-lg-6 ${!isMobile ? 'pl-special-56' : ''}`}>
      <InfoSection
        title='APPOINTMENT LENGTH'
        content='Please allow up to 45 minutes for the first consultation and up to 40 minutes for subsequent consultations.'
      />
      <InfoSection
        title='WHAT TO WEAR'
        content='Comfort is key, so we recommend wearing loose, comfortable clothing like a T-shirt or shorts. For an accurate diagnosis and treatment, your osteopath may ask you to remove certain clothing down to your underwear. A regular bra with a clip is preferred, as a sports bra may cover more of the back and spine, making treatment more difficult. Towels will be provided for draping. If you are uncomfortable or unable to remove clothing, please inform your osteopath.'
      />
      <InfoSection
        title='ACC'
        content='We are ACC registered and can open an ACC claim for your injury if needed. If you already have an ACC claim, please bring the number to your consultation. Additionally, please bring any medical notes, referral letters, test results, or imaging related to your injury to help us better understand your condition.'
      />
    </div>
  </div>
)

const InfoSection = ({ title, content }) => (
  <>
    <h2 className='display-5 fw-bold lh-1 mb-3 font-db'>{title}</h2>
    <p className='font-db mb-4'>{content}</p>
  </>
)

export default Services
