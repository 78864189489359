import React, { useState, useEffect } from 'react'
import LandiBustBg from '../assets/images/landi-bust-bg.png'
import SambiBustBg from '../assets/images/sambi-bust-bg.png'
import LandiBust from '../assets/images/landi-bust.png'
import SambiBust from '../assets/images/sambi-bust.png'

const teamMembers = [
  {
    name: 'LANDI RADEMEYER',
    qualificationOne: 'M.Ost 2016',
    qualificationTwo: 'Bach. Applied Science (Human Biology) 2012',
    qualificationThree: 'ACC Registered',
    imageMobile: LandiBust,
    imageDesktop: LandiBustBg,
    bio: [
      "Hello, I'm Landi, a dedicated and experienced osteopath committed to helping my patients achieve optimal health and well-being.",
      'I am passionate about helping individuals achieve their goals of pain management, enhanced flexibility, competing in their desired events and supporting the mother-baby dyad. I am originally from South Africa and have called NZ home since 2000. My training was at Unitec, where I gained a Bachelor of Applied Science (Human Biology) and Master of Osteopathy. Since then I have completed several courses in pediatrics, visceral (mobilization of organs), manipulation and many more.',
      'As an Osteopath I provide a wide variety of skills and knowledge to provide personalised treatments for all ages. I regularly look at the body as a puzzle and enjoy seeing how the pieces fit together, or searching for the missing piece to achieve optimum health.',
      'I love to see the impact that Osteopathy can make on the lives of others and look forward to using the skills of hands-on medicine to impact lives.',
    ],
  },
  {
    name: 'SAMBI NELSON',
    qualifications:
      'M.Ost 2016 \n B.A. Communications 2008 \n Certified Mat Pilates Level 1 Instructor 2010 \n Pilates Reformer and Trapeze Table Level 1 2011',
    imageMobile: SambiBust,
    imageDesktop: SambiBustBg,
    bio: [
      "Hello, I'm Sambi, I grew up in Japan, but moved to Rotorua in 2016, after completing my Osteopathy degree in the UK.",
      'I love working with my patients to help them not only recover from acute pain or injury, but also by working with them long term to improve general well being, prevent injury, or manage chronic pain. I believe that by educating our patients about their body and pain, we can empower them to live more fulfilling day to day lives.',
      'As an Osteopath I treat patients using a variety of techniques including structural, cranial, visceral osteopathy, as well as prescribing movement or exercises which complement the hands on treatment.',
      'I also have a background as a Pilates instructor and believe in movement as medicine. On days I am not treating patients in the clinic, you will generally find me teaching Pilates classes, or out and about with my 2 kids, taking advantage of the nature Rotorua has to offer.',
    ],
  },
]

function Team() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 991)

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 991)
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return (
    <div className='pos-rel'>
      <div className={`container ${!isMobile ? '' : 'px-4'}`}>
        <h1 className='display-5 fw-bold font-frl font-db'>Meet the team</h1>
        {teamMembers.map((member, index) => (
          <TeamMember key={member.name} member={member} isMobile={isMobile} isReverse={index % 2 !== 0} />
        ))}
      </div>
    </div>
  )
}

function TeamMember({ member, isMobile, isReverse }) {
  const { name, qualificationOne, qualificationTwo, qualificationThree, imageMobile, imageDesktop, bio } =
    member

  return (
    <div
      className={`row ${isReverse ? 'flex-lg-row-reverse' : 'flex-lg-row'} align-items-center g-5 ${
        isMobile ? 'py-4' : 'py-5'
      } `}
    >
      <div className='col-12 col-lg-6 pos-rel'>
        <img
          src={isMobile ? imageMobile : imageDesktop}
          className='d-block mx-lg-auto img-fluid'
          alt={name}
          width='700'
          height='500'
        />
      </div>
      <div className='col-12 col-lg-6'>
        <h2 className='display-5 fw-bold lh-1 mb-3 font-db'>{name}</h2>
        <p className='font-db-1'>{qualificationOne}</p>
        <p className='font-db-1'>{qualificationTwo}</p>
        <p className='font-db-1 mb-3'>{qualificationThree}</p>
        {bio.map((paragraph, index) => (
          <p key={index} className='mb-3'>
            {paragraph}
          </p>
        ))}
      </div>
    </div>
  )
}

export default Team
