import './Footer.css'
import Logo from '../../assets/images/footer-logo.svg'
import PhoneIcon from '../../assets/icons/phone-icon.svg'
import PinIcon from '../../assets/icons/pin-icon.svg'
import MailIcon from '../../assets/icons/mail-icon.svg'
import FbIcon from '../../assets/icons/fb.svg'
import Button from '../Button/Button.js'

function Footer() {
  return (
    <footer class='footer-container'>
      <div class='footer-wave'>
        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1200 120' preserveAspectRatio='none'>
          <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1200 120' preserveAspectRatio='none'>
            <path
              d='M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z'
              class='shape-fill'
            ></path>
            <path
              d='M0,0V56.29c57.79,32.2,123.59,42.17,188,38,80.36-7.37,156.33-43.31,236.8-47.5C508.64,42.43,592.34,73.67,673,92.05c79.27,23,158.3,29.88,239.4,18.08,46.15-8,89.85-22.84,134.45-34.34C1149.49,40,1283,-4.29,1200,72.47V0Z'
              fill='rgba(255,255,255,0.5)'
            ></path>
          </svg>
        </svg>
      </div>
      <div className='container'>
        <div className='row row-cols-1 row-cols-sm-2 row-cols-md-3 py-5'>
          <div className='col mb-3 d-none d-md-block'>
            <a href='/' className='d-flex align-items-center mb-3 link-body-emphasis text-decoration-none'>
              <img src={Logo} alt='' />
            </a>
          </div>
          <div className='col mb-3 text-white'>
            <h2 className='mb-4'>LET'S TALK</h2>
            <p className='mb-4 p-footer'>If you are unsure whether Osteopathy is right for you contact us.</p>
            <div className='d-flex mb-2 flex-row align-items-start'>
              <img style={{ marginRight: '16px' }} src={PhoneIcon} alt='' />
              <div>
                <p class='p-footer'>Landi: 022 095 9334</p>
                <p class='p-footer'>Sambi: 027 539 9663</p>
              </div>
            </div>
            <div className='d-flex mb-2'>
              <img style={{ marginRight: '16px' }} src={MailIcon} alt='' />
              <p class='p-footer'>lakesosteos@gmail.co.nz</p>
            </div>
            <div className='d-flex flex-row align-items-start'>
              <img style={{ marginRight: '16px' }} src={PinIcon} alt='' />
              <div>
                <p class='p-footer'>QE Health</p>
                <p class='p-footer'>1084 Hinemaru Street, Rotorua, 3010</p>
              </div>
            </div>
          </div>

          <div className='col mb-3 text-white'>
            <h2 className='mb-4'>AVAILABLE HOURS</h2>
            <p class='p-footer'>
              <strong>MONDAY - FRIDAY</strong>
            </p>
            <p class='mb-4 p-footer'>9:00AM - 3:00PM</p>
            <Button
              label='BOOK NOW'
              buttonType={'tertiary'}
              navigateExternal={
                'https://lakes-osteopathy.au4.cliniko.com/bookings?business_id=1461846366216004942'
              }
            />
            <div class='d-flex align-items-center mt-3'>
              <a
                href='https://www.facebook.com/profile.php?id=61564912253194'
                target='_blank'
                rel='noreferrer'
              >
                <img style={{ marginRight: '16px' }} src={FbIcon} alt='' />
              </a>
              <p>Follow us</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
