import React from 'react'
import { useNavigate } from 'react-router-dom'
import './Button.css'

function Button({
  label,
  buttonType,
  onClick,
  type = 'button',
  icon,
  disabled,
  navigateTo,
  navigateExternal,
}) {
  const navigate = useNavigate()

  const handleClick = () => {
    if (onClick) {
      onClick()
    }
    if (navigateTo) {
      navigate(navigateTo)
    }
    if (navigateExternal) {
      window.open(navigateExternal, '_blank')
    }
  }

  return (
    <button className={`button ${buttonType}`} onClick={handleClick} type={type} disabled={disabled}>
      {label && label}
    </button>
  )
}

export default Button
