import React, { useEffect, useState } from 'react'
import AccLogo from '../assets/images/acc-logo-reduced.svg'

const pricingData = [
  { service: 'Adult first appointment', price: 105 },
  { service: 'Adult follow-up appointment', price: 70 },
  { service: 'ACC - First appointment', price: 75, showAccLogo: true },
  {
    service: 'ACC - Follow-up appointment',

    price: 50,
    showAccLogo: true,
  },
  { service: 'Baby/child (under 15) appointment', price: 70 },
  {
    service: 'ACC - Baby/child (under 15) appointment',

    price: 50,
    showAccLogo: true,
  },
]

const Pricing = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 991)

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 991)
    }

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])
  // {`container ${!isMobile ? 'mb-120' : 'px-4'}`}
  return (
    <div className={`container font-db ${!isMobile ? 'mb-120' : 'px-4'}`}>
      <h1 className='display-5 fw-bold font-frl font-db mb-40'>Pricing</h1>
      <p>Please allow between 30 and 45 minutes per consultation.</p>
      <div className='row'>
        <PricingList isMobile={isMobile} />
        <AccInfo isMobile={isMobile} />
      </div>
    </div>
  )
}

const PricingList = ({ isMobile }) => (
  <div className={`col-12 col-lg-6 ${!isMobile ? 'pr-special-36' : 'mb-4'}`}>
    {pricingData.map((item, index) => (
      <PricingItem key={index} {...item} isFirst={index === 0} />
    ))}
  </div>
)

const PricingItem = ({ service, time, price, showAccLogo, isFirst }) => (
  <div className={`d-flex justify-content-between ${isFirst ? 'border-t' : ''} border-b py-3`}>
    <div>
      <span>{service}</span> <br></br>
      <span class='text-black-50'>{time}</span>
    </div>
    <span className='d-flex align-items-center'>
      {showAccLogo && <img src={AccLogo} alt='ACC' style={{ height: '20px', marginRight: '20px' }} />}${price}
    </span>
  </div>
)

const AccInfo = ({ isMobile }) => (
  <div className={`col-12 col-lg-6 ${!isMobile ? 'pl-special-36' : 'mb-4'}`}>
    <h3 className='mb-4'>ACC</h3>
    <p>
      We are ACC registered and can open an ACC claim for your injury if needed. If you already have an ACC
      claim, please bring the number to your consultation. Additionally, please bring any medical notes,
      referral letters, test results, or imaging related to your injury to help us better understand your
      condition.
    </p>
  </div>
)

export default Pricing
