import './Nav.css'
import { useState } from 'react'
import Logo from '../../assets/images/logo.svg'
import LogoDark from '../../assets/images/logo-dark.svg'
import Button from '../Button/Button'
import { useLocation } from 'react-router-dom'
import { useEffect } from 'react'

function Nav() {
  const location = useLocation()
  const { pathname } = location

  const isHomePage = location.pathname === '/'

  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 991)

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 991)
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen)
  }

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 991 && isMenuOpen) {
        setIsMenuOpen(false)
      }
    }

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [isMenuOpen])

  return (
    <div class={`${isHomePage ? 'nav-container' : ''}`}>
      <nav
        className={`container navbar navbar-expand-lg mb-5 nav-padding ${isMobile ? 'px-3' : ''} ${
          isHomePage ? 'nav-home' : ''
        }`}
      >
        <div className='container-fluid'>
          <a className='navbar-brand' href='/'>
            <img src={isHomePage ? Logo : LogoDark} className='logo' alt='Logo' />
          </a>
          <button
            className='navbar-toggler'
            type='button'
            aria-controls='navbarNav'
            aria-expanded={isMenuOpen}
            aria-label='Toggle navigation'
            onClick={toggleMenu}
          >
            <span className='navbar-toggler-icon'></span>
          </button>
          <div className={`collapse navbar-collapse ${isMenuOpen ? 'show' : ''}`} id='navbarNav'>
            <ul className='navbar-nav ms-auto'>
              <li className='nav-item'>
                <a
                  className={`nav-link ${isHomePage ? 'nav-light' : 'nav-dark'}`}
                  aria-current='page'
                  href='/'
                >
                  HOME
                </a>
              </li>
              <li className='nav-item'>
                <a
                  className={`nav-link ${pathname === '/services' ? 'active-custom' : ''} ${
                    isHomePage ? 'nav-light' : 'nav-dark'
                  }`}
                  href='/services'
                >
                  SERVICES
                </a>
              </li>
              <li className='nav-item'>
                <a
                  className={`nav-link ${pathname === '/pricing' ? 'active-custom' : ''} ${
                    isHomePage ? 'nav-light' : 'nav-dark'
                  }`}
                  href='/pricing'
                >
                  PRICING
                </a>
              </li>
              <li className='nav-item'>
                <a
                  className={`nav-link ${pathname === '/team' ? 'active-custom' : ''} ${
                    isHomePage ? 'nav-light' : 'nav-dark'
                  }`}
                  href='/team'
                >
                  TEAM
                </a>
              </li>
              <li className='nav-item'>
                <a
                  className={`nav-link ${pathname === '/contact' ? 'active-custom' : ''} ${
                    isHomePage ? 'nav-light' : 'nav-dark'
                  }`}
                  href='/contact'
                >
                  CONTACT
                </a>
              </li>
              <li className='nav-item'>
                <Button
                  label={'BOOK NOW'}
                  navigateExternal={
                    'https://lakes-osteopathy.au4.cliniko.com/bookings?business_id=1461846366216004942'
                  }
                  buttonType={`${isHomePage ? 'primary-light' : 'secondary-dark'}`}
                />
              </li>
            </ul>
          </div>
        </div>
      </nav>

      <div className={`fullscreen-nav ${isMenuOpen ? 'show' : ''}`} onClick={toggleMenu}>
        <ul className='navbar-nav'>
          <li className='nav-item'>
            <a className={`nav-link custom-font ${pathname === '/' ? 'active-custom' : ''}`} href='/'>
              HOME
            </a>
          </li>
          <li className='nav-item'>
            <a className={`nav-link ${pathname === '/services' ? 'active-custom' : ''}`} href='/services'>
              SERVICES
            </a>
          </li>
          <li className='nav-item'>
            <a className={`nav-link ${pathname === '/pricing' ? 'active-custom' : ''}`} href='/pricing'>
              PRICING
            </a>
          </li>
          <li className='nav-item'>
            <a className={`nav-link ${pathname === '/team' ? 'active-custom' : ''}`} href='/team'>
              TEAM
            </a>
          </li>
          <li className='nav-item'>
            <a className={`nav-link ${pathname === '/contact' ? 'active-custom' : ''}`} href='/contact'>
              CONTACT
            </a>
          </li>
        </ul>
      </div>

      {isHomePage && (
        <>
          <div class={`container min-vh-100 ${isMobile ? 'px-4' : ''}`}>
            <div class='row w-100'>
              <div class='col-12 col-md-6 d-flex flex-column justify-content-center text-white'>
                <h1 class='font-frl mb-24'>Nurturing wellness, one adjustment at a time</h1>
                <p class='mb-40'>
                  We are registered primary healthcare practitioners dedicated to restoring health and balance
                  through holistic methods. Trained in diagnosing and treating musculoskeletal injuries, we
                  utilise a variety of osteopathic techniques.
                </p>
                <Button
                  label={'BOOK NOW'}
                  buttonType={`${isHomePage ? 'primary-light btn-127w' : 'primary-dark btn-127w'}`}
                  navigateExternal={
                    'https://lakes-osteopathy.au4.cliniko.com/bookings?business_id=1461846366216004942'
                  }
                />
              </div>
              <div class='col-12 col-md-6 d-none d-md-block'></div>
            </div>
          </div>
          <svg
            class='sine-wave-nav'
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 1440 320'
            preserveAspectRatio='none'
          >
            <path
              fill='white'
              fill-opacity='1'
              d='M0,224 C288,282,576,166,864,212 C1152,258,1440,194,1728,224 L1728,320 L0,320 Z'
            ></path>
            <path
              fill='white'
              fill-opacity='0.15'
              d='M0,96 C288,32,576,256,864,192 C1152,128,1440,256,1728,192 L1728,320 L0,320 Z'
            ></path>
            <path
              fill='white'
              fill-opacity='0.5'
              d='M0,160 C288,224,576,128,864,192 C1152,256,1440,192,1728,160 L1728,320 L0,320 Z'
            ></path>
          </svg>
        </>
      )}
    </div>
  )
}

export default Nav
