import React, { useState, useEffect } from 'react'
import Button from '../components/Button/Button.js'
import Map from '../assets/images/map.svg'
import Modal from '../components/Modal/Modal.js'
import './pages.css'
import GoogleMapsIframe from '../components/GoogleMapsIFrame.js'

function Contact() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 991)
  const [formValues, setFormValues] = useState({
    name: '',
    phoneNumber: '',
    email: '',
    message: '',
  })
  const [warningMessage, setWarningMessage] = useState('')
  const [result, setResult] = useState('Form not submitted yet.')
  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 991)
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const handleChange = (event) => {
    const { name, value } = event.target
    setFormValues((prev) => ({ ...prev, [name]: value }))
  }

  const onSubmit = async (event) => {
    event.preventDefault()
    const emptyFields = Object.entries(formValues).filter(([_, value]) => !value)
    if (emptyFields.length > 0) {
      setWarningMessage(`Please fill in the following fields: ${emptyFields.map(([key]) => key).join(', ')}`)
      return
    }

    setResult('Sending....')
    const formData = new FormData(event.target)
    formData.append('access_key', process.env.REACT_APP_WEB3FORMS_ACCESS_KEY)

    // Set the subject line with the person's name
    formData.append('subject', `New inquiry from ${formValues.name} - Lakes Osteopathy`)

    // Create a more structured email body
    const emailBody = ` 
      ${formValues.message}
    `
    formData.append('message', emailBody)

    try {
      const response = await fetch('https://api.web3forms.com/submit', {
        method: 'POST',
        body: formData,
      })
      const data = await response.json()

      if (data.success) {
        setResult(
          <div>
            <p>Thank you for contacting Lakes Osteopathy!</p>
            <br />
            <p>We appreciate your interest and will get back to you as soon as possible.</p>
            <br />
            <p>If you need immediate assistance, please call us directly at 022 095 9334 or 027 238 8222.</p>
          </div>
        )
        event.target.reset()
        setShowModal(true)
        setFormValues({
          name: '',
          phoneNumber: '',
          email: '',
          message: '',
        })
        setWarningMessage('')
      } else {
        console.log('Error', data)
        setResult(data.message)
      }
    } catch (error) {
      console.error('Error submitting form:', error)
      setResult('An error occurred. Please try again.')
    }
  }

  return (
    <div className={`container ${!isMobile ? 'mb-120' : 'px-4'}`}>
      <h1 className='display-5 fw-bold font-frl font-db mb-40'>Contact us</h1>
      <div className={`row mb-5 ${isMobile ? 'flex-column-reverse' : ''}`}>
        <div className={`col-12 ${isMobile ? 'col-lg-12' : 'col-lg-6'}`}>
          <ContactForm
            formValues={formValues}
            handleChange={handleChange}
            onSubmit={onSubmit}
            warningMessage={warningMessage}
            isMobile={isMobile}
          />
        </div>
        <div className={`col-12 ${isMobile ? 'col-lg-12 mb-5' : 'col-lg-6'}`}>
          <ContactInfo isMobile={isMobile} />
        </div>
      </div>
      {showModal && <Modal handleClose={() => setShowModal(false)} message={result} />}
    </div>
  )
}

const ContactForm = ({ formValues, handleChange, onSubmit, warningMessage, isMobile }) => (
  <form className={`${!isMobile ? 'pr-special-43' : 'mb-4'}`} onSubmit={onSubmit}>
    <div className='row g-3'>
      {['name', 'phoneNumber', 'email'].map((field) => (
        <div className='col-12' key={field}>
          <label htmlFor={field} className='form-label'>
            {field === 'phoneNumber' ? 'Phone Number' : field.charAt(0).toUpperCase() + field.slice(1)}
          </label>
          <input
            type={field === 'email' ? 'email' : 'text'}
            name={field}
            className='form-control'
            id={field}
            value={formValues[field]}
            onChange={handleChange}
          />
        </div>
      ))}
      <div className='col-12 mb-3'>
        <label htmlFor='message' className='form-label'>
          Your message
        </label>
        <textarea
          className='form-control'
          name='message'
          id='message'
          rows='3'
          value={formValues.message}
          onChange={handleChange}
        ></textarea>
      </div>
      <div className='col-12 d-flex align-items-center'>
        <button type='submit' className='button primary-light me-3'>
          SEND MESSAGE
        </button>
        {warningMessage && (
          <div className='alert alert-warning mb-0' role='alert'>
            {warningMessage}
          </div>
        )}
      </div>
    </div>
  </form>
)

const ContactInfo = ({ isMobile }) => (
  <div className={`${!isMobile ? 'pl-special-43' : ''}`}>
    <div className='d-flex flex-column flex-md-row mb-5'>
      <div className='mb-4 mb-md-0' style={{ marginRight: '80px' }}>
        <h2>CONTACT NUMBER</h2>
        <p>Landi: 022 095 9334</p>
        <p>Sambi: 027 539 9663</p>
      </div>
      <div>
        <h2>PHYSICAL ADDRESS</h2>
        <p>QE Health</p>
        <p>1084 Hinemaru Street</p>
        <p>Rotorua, 3010</p>
      </div>
    </div>
    <div className='ratio ratio-4x3'>
      <GoogleMapsIframe />
    </div>
  </div>
)

export default Contact
