import React, { useEffect, useState } from 'react'
import PatientGeneric from '../assets/images/patient-generic-1.png'
import KneeIcon from '../assets/icons/knee-icon.svg'
import BabyBottleIcon from '../assets/icons/baby-bottle-icon.svg'
import HeadIcon from '../assets/icons/head-icon.svg'
import PregnantIcon from '../assets/icons/pregnant-icon.svg'
import ACCLOGO from '../assets/images/acc-logo.svg'
import OCNZ from '../assets/images/ocnz-logo.svg'
import SCHI from '../assets/images/schi-logo.svg'
import Button from '../components/Button/Button'
import StaffFlat from '../assets/images/staff.svg'

const treatmentCards = [
  {
    icon: KneeIcon,
    title: 'ACCIDENTS & INJURIES',
    text: 'Assisting in the recovery from accidents and injuries by addressing musculoskeletal issues, reducing pain, and promoting healing.',
  },
  {
    icon: HeadIcon,
    title: 'CHRONIC PAIN',
    text: 'Relieve chronic pain by improving joint mobility, reducing muscle tension, and encouraging optimal health.',
  },
  {
    icon: PregnantIcon,
    title: 'PRE- & POST PARTUM',
    text: 'Providing vital support for pregnancy and postpartum, addressing discomforts and aiding recovery for expectant and new mothers alike.',
  },
  {
    icon: BabyBottleIcon,
    title: 'BABIES, INFANTS + KIDS',
    text: 'Aid infants and toddlers by addressing issues such as colic, reflux, and musculoskeletal imbalances, facilitating overall health.',
  },
]

function Home() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 991)

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 991)
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return (
    <div>
      <HelpSection isMobile={isMobile} />
      <TeamSection isMobile={isMobile} />
      <TreatmentSection isMobile={isMobile} />
      <CareSection isMobile={isMobile} />
    </div>
  )
}

const HelpSection = ({ isMobile }) => (
  <div className={`container ${isMobile ? 'mb-104 px-4' : 'mb-120'}`}>
    <div className='row align-items-center'>
      <div className={`col-12 col-lg-6 ${!isMobile ? 'pr-special' : 'mb-4'}`}>
        <img
          src={PatientGeneric}
          className='d-block mx-lg-auto img-fluid'
          alt='Patient'
          width='700'
          height='500'
        />
      </div>
      <div className={`col-12 col-lg-6 ${!isMobile ? 'pl-special' : 'mb-5'}`}>
        <h2 className='display-5 fw-bold lh-1 mb-4 font-db'>HOW CAN WE HELP</h2>
        <p className='mb-32 font-db'>
          We provide personalised osteopathic treatments for individuals of all ages, utilising techniques
          such as massage, stretching, gentle manipulation, and more to enhance functionality and reduce pain.
          Our mission is to deliver effective, friendly care that addresses the root cause of symptoms and
          promotes overall well-being.
        </p>
        <div className='d-grid gap-2 d-md-flex justify-content-md-start'>
          <Button label='LEARN MORE' buttonType='secondary-dark' navigateTo='/services' />
        </div>
      </div>
    </div>
  </div>
)

const TeamSection = ({ isMobile }) => {
  return (
    <div className={`${isMobile ? 'px-4 mb-5 theme-background-2' : 'container'}`}>
      <div className={`team-section ${isMobile ? '' : 'staff-theme-background'}`}>
        <div className={`row align-items-center g-5 flex-lg-row-reverse ${isMobile ? '' : 'px-5'}`}>
          <div className={`col-12 col-lg-6 ${isMobile ? '' : 'mb-48'}`}>
            <img
              src={StaffFlat}
              className='d-block mx-lg-auto img-fluid'
              alt='Bootstrap Themes'
              width='700'
              height='500'
            />
          </div>
          <div className={`col-12 col-lg-6 text-white ${isMobile ? 'mb-5' : ''}`}>
            <h2 className='display-5 fw-bold lh-1 mb-3'>MEET THE TEAM</h2>
            <p className='mb-3'>
              With a collective experience of over 15 years. Together we provide the best management for your
              pain.
            </p>
            <div className='d-grid gap-2 d-md-flex justify-content-md-start'>
              <Button label='VIEW TEAM' buttonType='secondary-light' navigateTo='/team' />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const TreatmentSection = ({ isMobile }) => (
  <div className={`container ${isMobile ? 'px-4' : 'py-5'}`}>
    <h2 className={`${isMobile ? 'mb-4' : 'mb-5'}`}>
      <strong>WHAT WE TREAT</strong>
    </h2>
    <div className='row row-cols-1 row-cols-sm-2 row-cols-md-4 g-4 mb-5'>
      {treatmentCards.map((card, index) => (
        <div className='col' key={index}>
          <div className='card-custom'>
            <div className='card-body'>
              <img className='mb-4' src={card.icon} alt={`Icon for ${card.title}`} />
              <h2 className='mb-3'>
                <strong>{card.title}</strong>
              </h2>
              <p className='card-text'>{card.text}</p>
            </div>
          </div>
        </div>
      ))}
    </div>
  </div>
)

const CareSection = ({ isMobile }) => (
  <div className={`container ${isMobile ? 'px-4' : ''}`}>
    <div className='row align-items-center'>
      <div className='col-12 text-center mb-3 d-md-none'>
        <h2 className='font-db'>PROVIDING THE BEST CARE</h2>
      </div>
      <div className='col-12 col-md-auto text-center mb-3 mb-md-5 d-flex justify-content-center'>
        <img src={ACCLOGO} alt='ACC logo' className='treatment-logo mr-10' />
        <img src={OCNZ} alt='OCNZ logo' className='treatment-logo mx-2' />
      </div>
      <div className='col-12 col-md font-db'>
        <h2 className='mb-4 d-none d-md-block'>PROVIDING THE BEST CARE</h2>
        <p>
          We're ACC registered and adhere to the regulations set by the Osteopathic Council of New Zealand,
          ensuring your care meets the highest standards.
          <strong>Plus, there's no need for a referral to visit us.</strong>
        </p>
      </div>
    </div>
  </div>
)

export default Home
