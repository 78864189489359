import React from 'react'

const GoogleMapsIframe = () => {
  return (
    <iframe
      src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3138.3051340707448!2d176.2534402764427!3d-38.13309727189719!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6d6c277fd2d5097b%3A0x5949a3d5c9cfbf14!2s1084%20Hinemaru%20Street%2C%20Rotorua%203010!5e0!3m2!1sen!2snz!4v1724486776399!5m2!1sen!2snz'
      width='600'
      height='450'
      style={{ border: 0 }}
      allowFullScreen=''
      loading='lazy'
      referrerPolicy='no-referrer-when-downgrade'
      title='Google Maps - 1084 Hinemaru Street, Rotorua'
    />
  )
}

export default GoogleMapsIframe
