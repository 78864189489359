import React from 'react'
import './Modal.css' // Assuming you have some custom styles for the modal

const Modal = ({ handleClose, message }) => {
  return (
    <div className='custom-modal-overlay'>
      <div className='custom-modal'>
        <div className='custom-modal-header'>
          <h5 className='custom-modal-title'>Message sent</h5>
          <button type='button' className='custom-close' onClick={handleClose}>
            &times;
          </button>
        </div>
        <div className='custom-modal-body'>{typeof message === 'string' ? <p>{message}</p> : message}</div>
        <div className='custom-modal-footer'>
          <button type='button' className='button primary-light' onClick={handleClose}>
            Close
          </button>
        </div>
      </div>
    </div>
  )
}

export default Modal
