// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyCORp6-5obTN_tI_EIhkLOgvownr8ctx4g',
  authDomain: 'lakes-4569f.firebaseapp.com',
  projectId: 'lakes-4569f',
  storageBucket: 'lakes-4569f.appspot.com',
  messagingSenderId: '11825990607',
  appId: '1:11825990607:web:98e7e0c66ff198c2d960ba',
  measurementId: 'G-JNDQYBHCD1',
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
const analytics = getAnalytics(app)

export { analytics }
