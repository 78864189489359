import { useState } from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Home from './pages/Home'
import Services from './pages/Services'
import Team from './pages/Team'
import Contact from './pages/Contact'
import Nav from './components/Nav/Nav'
import Footer from './components/Footer/Footer'
import Pricing from './pages/Pricing'

function App() {
  const [password, setPassword] = useState('')
  const [isAuthenticated, setIsAuthenticated] = useState(false)

  const handlePasswordChange = (event) => {
    setPassword(event.target.value)
  }

  const handlePasswordSubmit = (event) => {
    event.preventDefault()
    if (password === 'Sambi&Landi') {
      setIsAuthenticated(true)
    } else {
      setPassword('')
    }
  }

  return (
    <Router>
      <Nav />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='services' element={<Services />} />
        <Route path='pricing' element={<Pricing />} />
        <Route path='team' element={<Team />} />
        <Route path='contact' element={<Contact />} />
      </Routes>
      <Footer />
    </Router>
  )
}

export default App
